<template lang="pug">
  .container-fluid.mt-3.mb-3
    .booking-ticket-details
      .address
        .row
          .col-12
            .card
              .card-header
                h5 {{ $t('mainTraveler') }}

              .card-body
                .row
                  .col-12
                    .form-group
                      label.form-label {{ $t("customer-data.guestMail") }}
                      input.form-control(:placeholder="this.$t('customer-data.guestMailPlaceholder')" v-model="address.Email")

                .row.mb-2(v-if="addresses && Array.isArray(addresses) && addresses.length")
                  .col-12
                    select.form-control(v-model="localAddress")
                      option(v-for="address in addresses" :value="address")
                        span {{ address.FormOfAddress ? `${address.FormOfAddress} ` : '' }}{{ address.FirstName }} {{ address.LastName }}, {{ address.Street }}, {{ address.PostalCode }} {{ address.City }}{{ address.Country ? ` ${address.Country}` : '' }}{{ address.Phone ? `, ${address.Phone}` : '' }}{{ address.BirthDate ? `, ${moment(address.BirthDate).format('DD.MM.YYYY')}` : '' }}

                .row
                  .col-12.col-md-4
                    .form-group
                      label.form-label {{ $t("customer-data.formOfAddress") }}
                      select.form-control(v-model="address.FormOfAddress")
                        option(value="Herr") {{ $t("customer-data.gender.m") }}
                        option(value="Frau") {{ $t("customer-data.gender.f") }}
                  .col-12.col-md-4
                    .form-group
                      label.form-label {{ $t("customer-data.firstName") }}
                      input.form-control(:placeholder="this.$t('customer-data.firstNamePlaceholder')" v-model="address.FirstName")
                  .col-12.col-md-4
                    .form-group
                      label.form-label {{ $t("customer-data.lastName") }}
                      input.form-control(:placeholder="this.$t('customer-data.lastNamePlaceholder')" v-model="address.LastName")
                .row
                  .col-12.col-md-4
                    .form-group
                      label.form-label {{ $t("customer-data.streetAndNumber") }}
                      input.form-control(:placeholder="this.$t('customer-data.streetAndNumberPlaceholder')" v-model="address.Street")
                  .col-12.col-md-4
                    .form-group
                      label.form-label {{ $t("customer-data.zipCode") }}
                      input.form-control(:placeholder="this.$t('customer-data.zipCodePlaceholder')" v-model="address.PostalCode")
                  .col-12.col-md-4
                    .form-group
                      label.form-label {{ $t("customer-data.city") }}
                      input.form-control(:placeholder="this.$t('customer-data.cityPlaceholder')" v-model="address.City")
                .row
                  .col-12.col-md-4
                    .form-group
                      label.form-label {{ $t("customer-data.country") }}
                      select.form-control(v-model="address.Country")
                        option(:value="addressCountry.ISO31661alpha2" v-for="addressCountry in addressCountries") {{ addressCountry[$t("customer-data.countryProp")] }}
                  .col-12.col-md-4
                    .form-group
                      label.form-label {{ $t("customer-data.phone") }}
                      input.form-control(:placeholder="this.$t('customer-data.phonePlaceholder')" v-model="address.Phone")
                  .col-12.col-md-4
                    .form-group
                      label.form-label {{ $t("customer-data.dateOfBirth") }}
                      input.form-control(type="date" :placeholder="this.$t('customer-data.datePlaceholder')" :value="address.BirthDate | formatInputDate" @change="address.BirthDate = $event.target.value;")

                .row(v-if="baseVoucher")
                  .col-12
                    .float-right
                      button.button.button-primary.button-tbook(@click="pasteInvoiceData")
                        font-awesome-icon.mr-2(:icon="['fas', 'paste']")
                        span Daten aus Lieferadresse einfügen

      .booking-ticket-vouchers.mt-3
        .row
          .col-12
            .card
              .card-header
                h5 {{ $t('vouchers') }}

              .card-body
                .row
                  .col-lg-6.col-md-12
                    .card.h-100
                      .card-header
                        h5 {{ $t('addVoucher') }}
                      .card-body
                        .form-group(v-if="vouchers && Array.isArray(vouchers) && vouchers.length")
                          label.form-label {{ $t('vouchers') }}
                          select.form-control(v-model="voucher")
                            option(:value="undefined") Ihre Gutscheine...
                            option(v-for="voucher in vouchers" :value="voucher") {{ voucher.Id }}:&nbsp;{{ voucher.Arrangement }}
                        .form-group
                          label.form-label Gutscheinnummer
                          input.form-control(type="text" v-model="voucherId")
                        button.button.button-primary.button-tbook.w-100.mt-2(
                          @click="addBookingTicketVoucher"
                          :disabled="!voucher && !voucherId"
                          :class="{ 'disabled': !voucher && !voucherId }"
                        )
                          font-awesome-icon.mr-2(:icon="['fas', 'plus']")
                          span {{ $t('addVoucher') }}

                  .col-lg-6.col-md-12.mt-md-3.mt-lg-0(
                    v-for="bookingTicketVoucher in bookingTicketVouchers"
                    :class="{ 'mt-lg-3': bookingTicketVouchers.indexOf(bookingTicketVoucher) > 0, 'mt-lg-0': bookingTicketVouchers.indexOf(bookingTicketVoucher) == 0 }"
                  )
                    booking-ticket-voucher-edit-card(
                      :booking-ticket-voucher="bookingTicketVoucher"
                      :delete-booking-ticket-voucher="deleteBookingTicketVoucher"
                    )

      .booking-ticket-type.mt-3
        .card
          .card-header
            h5 Buchungstyp
          .card-body
            .row
              .col-lg-6.col-md-12
                button.button.button-primary.w-100.h-100(
                  @click="bookingTicket.BookingTicketTypeId = BookingTicketTypesEnum.SameArrivalDay"
                  :class="{ 'button-tdays': bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.SameArrivalDay, 'button-td-grey': bookingTicket.BookingTicketTypeId != BookingTicketTypesEnum.SameArrivalDay }"
                )
                  br
                  font-awesome-icon(:icon="['fas', 'calendar-check']" size="4x")
                  br
                  br
                  p(style="font-size: 20px;") Einzel-/Gruppenreise
              .col-lg-6.col-md-12.mt-md-3.mt-lg-0
                button.button.button-primary.w-100.h-100(
                  @click="bookingTicket.BookingTicketTypeId = BookingTicketTypesEnum.CombineTravelPeriod"
                  :class="{ 'button-tdays': bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.CombineTravelPeriod, 'button-td-grey': bookingTicket.BookingTicketTypeId != BookingTicketTypesEnum.CombineTravelPeriod, 'disabled': bookingTicketVouchers.length == 1 }"
                  :disabled="bookingTicketVouchers.length == 1"
                )
                  br
                  font-awesome-icon(:icon="['fas', 'calendar-plus']" size="4x")
                  br
                  br
                  p(style="font-size: 20px;") Kombireise

      .booking-date-request.mt-3
        .row
          .col-12
            .card
              .card-header
                h5 Buchungsdatum

              .card-body
                .row(v-if="bookingDateRequest.Date")
                  .col-12
                    booking-date-requests(
                      :booking-ticket="bookingTicket"
                      :booking-ticket-vouchers="bookingTicketVouchers"
                      :booking-date-requests="[bookingDateRequest]"
                      :show-booking-date-request-status="false"
                    )
                .row.mt-2
                  .col-12
                    booking-date-picker(
                      :select-date="(date) => {bookingDateRequest.Date = date}"
                    )

      .row.mt-3
        .col-12
          .float-right
            button.button.button-primary.button-tbook(@click="saveBookingTicket")
              font-awesome-icon.mr-2(:icon="['fas', 'floppy-disk']")
              span Buchung speichern

</template>

<script>
import { EmailValidatorComponent } from "@/lib/components/emailValidator/EmailValidatorComponent";
import AddressesComponent from "@/lib/components/addresses/AddressesComponents";
import AddressCountriesComponent from "@/lib/components/addressCountries/AddressCountriesComponent";
import moment from "moment";
import { BookingTicketVoucherStatusEnum } from "@/lib/enums/bookingTicketVoucherStatus/BookingTicketVoucherStatusEnum.ts";
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";
import VoucherStatusEnum from "@/lib/enums/voucherStatus/VoucherStatusEnum";
import VoucherPreview from "@/views/vouchers/VoucherPreview.vue";
import BookingTicketVoucherEditCard from "@/views/bookingTicketVouchers/BookingTicketVoucherEditCard.vue";
import BookingDatePicker from "@/views/bookingDatePicker/BookingDatePicker.vue";
import { BookingDateRequestStatusEnum } from "@/lib/enums/bookingDateRequestStatus/BookingDateRequestStatusEnum";
import { BookingTicketTypesEnum } from "@/lib/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";
import BookingTicketsComponent from "@/lib/components/bookingTickets/BookingTicketsComponent";
import EventBus from "@/views/event-bus";
import BookingDateRequests from "@/views/bookingDateRequests/BookingDateRequests.vue";
import { OriginPlatformsEnum } from "@/lib/enums/originPlatforms/OriginPlatformsEnum.ts";
import { BookingTicketStatusEnum } from "@/lib/enums/bookingTicketStatus/BookingTicketStatusEnum";
import OrdersComponent from "@/lib/components/orders/OrdersComponent";

export default {
    name: "BookingTicketDetails",
    components: {
        BookingDateRequests,
        BookingDatePicker,
        BookingTicketVoucherEditCard,
        VoucherPreview,
    },
    data() {
        return {
            bookingTicket: {
                BookingTicketStatusId: BookingTicketStatusEnum.Created,
                BookingTicketTypeId: BookingTicketTypesEnum.SameArrivalDay,
                OriginPlatformId: OriginPlatformsEnum.Dashboard,
            },
            baseVoucher: undefined,
            address: {},
            localAddress: {},
            addresses: [],
            addressCountries: [],

            bookingTicketVouchers: [],
            voucher: {},
            vouchers: [],
            voucherId: "",

            bookingDateRequest: {
                BookingDateRequestStatusId:
                    BookingDateRequestStatusEnum.DateBooked,
                Date: undefined,
            },

            BookingTicketTypesEnum: BookingTicketTypesEnum,
            moment: moment,
        };
    },
    async mounted() {
        try {
            this.loadAddressCountries();
            this.loadVouchers();
            this.loadBaseVoucher();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadBaseVoucher() {
            try {
                if (!this.$router.currentRoute.query.voucherGuid) {
                    return;
                }
                const vouchersComponent = new VouchersComponent();
                const voucher = await vouchersComponent.getVoucher(
                    this.$apollo,
                    undefined,
                    this.$router.currentRoute.query.voucherGuid
                );
                if (!voucher) {
                    return;
                }
                this.baseVoucher = voucher;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadAddressCountries() {
            try {
                const addressCountriesComponent =
                    new AddressCountriesComponent();
                const addressCountries =
                    await addressCountriesComponent.getAddressCountries(
                        this.$apollo
                    );
                if (!addressCountries) {
                    return;
                }
                this.addressCountries = addressCountries;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadAddresses() {
            try {
                const addressesComponent = new AddressesComponent();
                const addresses = await addressesComponent.getAddresses(
                    this.$apollo,
                    this.address?.Email
                );
                if (!addresses) {
                    return;
                }
                this.addresses = addresses;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadVouchers() {
            try {
                const vouchersComponent = new VouchersComponent();
                const vouchers = await vouchersComponent.getVouchers(
                    this.$apollo,
                    this.$session.get("hostId"),
                    undefined,
                    undefined,
                    [VoucherStatusEnum.Sent],
                    undefined,
                    moment().startOf("day").toDate()
                );
                if (!vouchers) {
                    return;
                }
                this.vouchers = vouchers;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async addBookingTicketVoucher() {
            try {
                if (!this.voucher && !this.voucherId) {
                    return this.$alert(
                        "Bitte wählen Sie entweder einen Gutschein aus oder geben eine Gutscheinnummer an."
                    );
                }
                let voucher = undefined;
                const vouchersComponent = new VouchersComponent();
                if (this.voucher) {
                    voucher = await vouchersComponent.getVoucher(
                        this.$apollo,
                        this.voucher.Id
                    );
                    if (!voucher) {
                        return this.$alert(
                            `Kein Gutschein für ${this.voucherId} gefunden.`
                        );
                    }
                } else if (this.voucherId) {
                    voucher = await vouchersComponent.getVoucher(
                        this.$apollo,
                        this.voucherId
                    );
                    if (!voucher) {
                        return this.$alert(
                            `Kein Gutschein für ${this.voucherId} gefunden.`
                        );
                    }
                }
                if (this.voucher.AddressId != this.$session.get("hostId")) {
                    return this.$alert(
                        "Der Gutschein gehört nicht zum aktuell ausgewählten Gastgeber."
                    );
                }
                if (
                    this.bookingTicketVouchers.some(
                        (bookingTicketVoucher) =>
                            bookingTicketVoucher.VoucherId == voucher.Id
                    )
                ) {
                    return this.$alert(
                        "Die Gutschein ist bereits teil der Buchung."
                    );
                }
                this.bookingTicketVouchers.push({
                    VoucherId: voucher.Id,
                    BookingTicketVoucherStatusId:
                        BookingTicketVoucherStatusEnum.DatesBooked,
                });
                this.voucherId = "";
                this.voucher = undefined;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async deleteBookingTicketVoucher(bookingTicketVoucher) {
            try {
                if (!bookingTicketVoucher) {
                    return;
                }
                this.bookingTicketVouchers.splice(
                    this.bookingTicketVouchers.indexOf(bookingTicketVoucher),
                    1
                );
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async saveBookingTicket() {
            try {
                const validatedAddress = this.validateAddress();
                if (!validatedAddress) {
                    return this.$alert(
                        "Die Adressdaten konnten nicht überprüft werden."
                    );
                }
                if (!validatedAddress.IsSuccess) {
                    return this.$alert(validatedAddress.Message);
                }
                if (!this.bookingDateRequest.Date) {
                    return this.$alert(this.$t("booking-ticket-details.date"));
                }
                const bookingTicketsComponent = new BookingTicketsComponent();
                EventBus.$emit("changeLoadingState", true);
                let savedBookingTicket =
                    await bookingTicketsComponent.saveBookingTicket(
                        this.$apollo,
                        this.bookingTicket,
                        undefined,
                        undefined,
                        this.address
                    );
                EventBus.$emit("changeLoadingState", false);
                if (!savedBookingTicket) {
                    return this.$alert(
                        "Die Buchung konnte nicht gespeichert werden."
                    );
                }
                if (!savedBookingTicket.IsSuccess) {
                    return this.$alert(savedBookingTicket.Message);
                }
                this.bookingTicket.Id = savedBookingTicket.Id;
                this.bookingTicketVouchers.forEach(
                    (bookingTicketVoucher) =>
                        (bookingTicketVoucher.BookingTicketId =
                            this.bookingTicket.Id)
                );
                this.bookingDateRequest.BookingTicketId = this.bookingTicket.Id;
                EventBus.$emit("changeLoadingState", true);
                savedBookingTicket =
                    await bookingTicketsComponent.saveBookingTicket(
                        this.$apollo,
                        this.bookingTicket,
                        this.bookingTicketVouchers,
                        [this.bookingDateRequest],
                        this.address
                    );
                EventBus.$emit("changeLoadingState", false);
                if (savedBookingTicket.Message) {
                    this.$alert(savedBookingTicket.Message);
                }
                if (savedBookingTicket.IsSuccess) {
                    this.$router
                        .push({ name: "BookingTicketsList" })
                        .catch(() => {});
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        validateAddress() {
            try {
                if (!this.address?.Email) {
                    return {
                        IsSuccess: false,
                        Message: this.$t(
                            "booking-ticket-details.address.email"
                        ),
                    };
                }
                if (!this.address?.FormOfAddress) {
                    return {
                        IsSuccess: false,
                        Message: this.$t(
                            "booking-ticket-details.address.formOfAddress"
                        ),
                    };
                }
                if (!this.address?.FirstName) {
                    return {
                        IsSuccess: false,
                        Message: this.$t(
                            "booking-ticket-details.address.firstName"
                        ),
                    };
                }
                if (!this.address?.LastName) {
                    return {
                        IsSuccess: false,
                        Message: this.$t(
                            "booking-ticket-details.address.lastName"
                        ),
                    };
                }
                if (!this.address?.Street) {
                    return {
                        IsSuccess: false,
                        Message: this.$t(
                            "booking-ticket-details.address.street"
                        ),
                    };
                }
                if (!this.address?.PostalCode) {
                    return {
                        IsSuccess: false,
                        Message: this.$t(
                            "booking-ticket-details.address.postalCode"
                        ),
                    };
                }
                if (!this.address?.City) {
                    return {
                        IsSuccess: false,
                        Message: this.$t("booking-ticket-details.address.city"),
                    };
                }
                if (!this.address?.Country) {
                    return {
                        IsSuccess: false,
                        Message: this.$t(
                            "booking-ticket-details.address.country"
                        ),
                    };
                }
                return {
                    IsSuccess: true,
                };
            } catch (e) {
                console.error(e);
                return {
                    IsSuccess: false,
                    Message: e.message,
                };
            }
        },
        async pasteInvoiceData() {
            try {
                const ordersComponent = new OrdersComponent();
                EventBus.$emit("changeLoadingState", true);
                const order = await ordersComponent.getOrder(
                    this.$apollo,
                    undefined,
                    this.baseVoucher.Id
                );
                EventBus.$emit("changeLoadingState", false);
                if (!order) {
                    return this.$alert(
                        `Kein Auftrag für den Gutschein ${this.baseVoucher.Id} gefunden.`
                    );
                }
                const addressesComponent = new AddressesComponent();
                EventBus.$emit("changeLoadingState", true);
                const deliveryAddress = await addressesComponent.getAddress(
                    this.$apollo,
                    order.DeliveryAddressId
                );
                EventBus.$emit("changeLoadingState", false);
                if (!deliveryAddress) {
                    return this.$alert(
                        `Keine Lieferadresse für Auftrag ${order.Id} gefunden.`
                    );
                }
                const address = JSON.parse(JSON.stringify(deliveryAddress));
                delete address.Id;
                this.address = address;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    watch: {
        baseVoucher() {
            try {
                if (!this.baseVoucher) {
                    return;
                }
                this.bookingTicketVouchers.push({
                    VoucherId: this.baseVoucher.Id,
                    BookingTicketVoucherStatusId:
                        BookingTicketVoucherStatusEnum.DatesBooked,
                });
            } catch (e) {
                console.error(e);
                return this.$alert(e.message);
            }
        },
        "address.Email"() {
            try {
                if (!this.address?.Email?.length) {
                    return;
                }
                const emailValidatorComponent = new EmailValidatorComponent();
                const isEmailValid = emailValidatorComponent.validateEmail(
                    this.address?.Email
                );
                if (!isEmailValid) {
                    return;
                }
                this.loadAddresses();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
