<template lang="pug">
.cancel-booking-ticket-modal
  .p-5.text-center
    .row.mb-3
      .col-12
        small Der Gutscheincode ist auf dem Gutschein des Gastes ausgewiesen. Dieser wird benötigt um eine Buchung einvernehmlich zu stornieren.
    template(v-for="voucherCheck in voucherChecks")
      .row(v-if="voucherChecks.indexOf(voucherCheck)")
        .col-12
          hr
      .row.mt-2
        .col-12
          h5.font-weight-bold {{ Gutschein }} {{ voucherCheck.VoucherId }}:
      .row
        .col-12
          voucher-code-input(
            :voucher="voucherCheck"
            :check-voucher-code="checkVoucherCode"
          )

</template>

<script>
import VoucherCodeInput from "@/views/utils/voucherCode/VoucherCodeInput.vue";

export default {
    name: "CancelBookingTicketModal",
    components: { VoucherCodeInput },
    props: {
        bookingTicketVouchers: {
            type: Array,
            required: true,
        },
        vouchers: {
            type: Array,
            required: true,
        },
        cancelBookingTicket: {
            type: Function,
            required: true,
        },
    },
    methods: {
        checkVoucherCode(voucherCheck, code) {
            try {
                if (!voucherCheck || !code) {
                    return;
                }
                if (voucherCheck.Code.toLowerCase() != code.toLowerCase()) {
                    return this.$alert("Code stimmt nicht überein.");
                }
                voucherCheck.IsSuccess = true;
                if (
                    !this.voucherChecks.every(
                        (voucherCheck) => voucherCheck.IsSuccess
                    )
                ) {
                    return;
                }
                this.cancelBookingTicket();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    computed: {
        voucherChecks() {
            try {
                const voucherChecks = [];
                for (const bookingTicketVoucher of this.bookingTicketVouchers) {
                    const voucher = this.vouchers.find(
                        (voucher) =>
                            voucher.Id == bookingTicketVoucher.VoucherId
                    );
                    if (!voucher) {
                        continue;
                    }
                    voucherChecks.push({
                        VoucherId: bookingTicketVoucher.VoucherId,
                        Code: voucher.Guid.slice(-6).toLowerCase(),
                        IsSuccess: false,
                    });
                }
                return voucherChecks;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
};
</script>

<style scoped></style>
