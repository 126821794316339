import gql from "graphql-tag";

const _props = `
    Id
    OrderId
    CustomerID
    CreatedAt
    PaidAt
    OrderDate
    TotalInvoiceGross
    AlreadyPaid
    PaymentStatusId
    UpdatedAt
    Cancelled
    CancelDate
    Country
    OrderStatusId
    ConfirmationMailSentDate
    VoucherMailSentDate
    CancelMailSentDate
    GoodsIssueBookedOn
    BillingAddressId
    DeliveryAddressId
`;

export const Orders = {
    Queries: {
        Order: gql`
        query order($id: ID, $voucherId: ID) {
            order(id: $id, voucherId: $voucherId) {
                ${_props}
            }
        }`,
        OrderByVoucherId: gql`
        query orderByVoucherId($voucherId: ID) {
            orderByVoucherId(voucherId: $voucherId) {
                ${_props}
            }
        }`,
    },
};
